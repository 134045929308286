<template>
  <div class="not-found-wrapper">
    <div class="not-found">
      <img class="not-found__img" src="@/assets/not-found/not-found.png" alt="not found">
      <BackButton class="not-found__back" />
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/ui/buttons/BackButton'

export default {
  name: 'NotFound',
  components: {
    BackButton
  }
}
</script>

<style scoped lang="scss">

.not-found-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 65%;

  &__img {
    width: 100%;
  }

  &__back {
    align-self: flex-end;
  }
}

@media (max-width: 640px) {
  .not-found {
    height: 50%;
  }
}
</style>