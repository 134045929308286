<template>
  <a class="back" @click="$router.go(-1)">
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" class="back--arrow">
      <g transform="translate(-0.234 0.106)">
        <rect width="27" height="27" transform="translate(0.234 -0.106)" fill="none"/>
        <path d="M9.968,20.937l1.605-1.605L6.36,14.107H24.767V11.83H6.36l5.225-5.225L9.968,5,2,12.968Z" transform="translate(0.319 0.672)" fill="#fff"/>
      </g>
    </svg>
    <span class="back--text">Назад</span>
  </a>
</template>

<script>


export default {
  name: 'BackButton',
}
</script>

<style scoped lang="scss">

.back {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 140px;
  height: 40px;
  padding: 10px 5px;
  border: 2px solid var(--main-red);
  border-radius: 34px;

  &:hover {
    background-color: var(--main-red);
    cursor: pointer;
  }
}

</style>
